import { createSlice } from '@reduxjs/toolkit'

export const waitModalSlice = createSlice({
    name: 'submodal',
    initialState: {
        isOpen : false,
        icon : "",
        size : "",
        title: ""
    },
    reducers: {
        openModal: (state, action) => {
            const {size,icon,title} = action.payload
            state.isOpen = true
            state.size = size || 'md'
            state.icon = icon
            state.title = title
          
        },

        closeModal: (state, action) => {
            state.isOpen = false
            state.icon = ""
            state.title = ""

        },
    }
})

export const { openModal, closeModal } = waitModalSlice.actions
export default waitModalSlice.reducer