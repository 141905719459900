import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { useAuth } from "./app/auth";
import initializeApp from "./app/init";
import { useState } from "react";
import { onValue, ref as rtdbRef, update } from "firebase/database";
import { H } from "highlight.run";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));

// Initializing different libraries
initializeApp();

function App() {
  // Check for login and initialize axios
  const auth = useAuth();
  //eslint-disable-next-line
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth?.user?.["screenName"] || auth?.user?.["displayName"]) {
      listenToSubscriptionData();
    }

    // eslint-disable-next-line
  }, [auth?.user]);

  const listenToSubscriptionData = async () => {
    //listen to the subscription data from realtime user database
    if (auth && auth.user) {
      const subscriptionRef = rtdbRef(
        auth?.rtdb,
        "subscription/" + auth?.user.localId
      );
      onValue(subscriptionRef, (snapshot) => {
        const data = snapshot.val();
        setSubscriptionData(data);
        if (!data) {
        } else {
          H.identify(
            auth?.user?.["screenName"] || auth?.user?.["displayName"],
            {
              firebaseId: auth?.user?.["localId"],
              status: data["status"],
            }
          );
          if (!data.seconds) {
            console.log("no");

            const subscriptionRef = rtdbRef(
              auth?.rtdb,
              "subscription/" + auth?.user.localId
            );

            update(subscriptionRef, {
              seconds: 300,
            })
              .then(() => {})
              .catch((error) => {
                console.error("Error updating credits:", error);
              });
          }
          // if (data.status === "Lite Plan" && !data.renewDateAt) {
          //   //first time subcribing for lite plan
          //   //set the limit and set the renew

          //   const updateSubscriptionInfo = httpsCallable(
          //     auth?.functions,
          //     "updateSubscriptionInfo"
          //   );

          //   const today = new Date();

          //   const renewalDate = new Date(today);
          //   renewalDate.setDate(today.getDate() + 30);
          //   //timestam
          //   updateSubscriptionInfo({
          //     renewDateAt: renewalDate.getTime(),
          //     credits: 30,
          //   });
          // } else if (
          //   data.status === "Lite Plan" &&
          //   data.renewDateAt <= new Date().getTime()
          // ) {
          //   const renewalDate = new Date(data.renewDateAt);
          //   renewalDate.setDate(renewalDate.getDate() + 30);
          //   //push renewDateAt 30 more dates
          //   //reset the limit
          //   const updateSubscriptionInfo = httpsCallable(
          //     auth?.functions,
          //     "updateSubscriptionInfo"
          //   );
          //   updateSubscriptionInfo({
          //     renewDateAt: renewalDate.getTime(),
          //     credits: 30,
          //   });
          // }
        }
      });
    }
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          {/* <Route path="/auto-generate" element={} /> */}
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route
            path="*"
            element={
              <Navigate to={auth?.user ? "/app/welcome" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
