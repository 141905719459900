import axios from "axios";
import React, { useState, useEffect, useContext, createContext } from "react";
import { initializeApp } from "firebase/app";
import {
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  TwitterAuthProvider,
  signInAnonymously,
  onAuthStateChanged,
  GoogleAuthProvider,
  getAuth,
  createUserWithEmailAndPassword
} from "firebase/auth";
import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  connectDatabaseEmulator,
  onValue,
} from "firebase/database";
import { useDispatch } from "react-redux";
import { showNotification } from "../features/common/headerSlice";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const rtdb = getDatabase(app);
const functions = getFunctions(app);
const storage = getStorage(app);

if (shouldConnectAuthEmulator()) {
  //console.log("DEV??");
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectDatabaseEmulator(rtdb, "localhost", 9000);
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
}

function shouldConnectAuthEmulator() {
  // You could do any logic here to decide whether to connect to the emulator or not
  return process.env.REACT_APP_ENVIRONMENT === "DEV-Emulator";
}

const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  //update the state of user subscription realtime database
  
  const listenToSubscriptionData = async () => {
    const subscriptionRef = ref(
      rtdb,
      "subscription/" + user.localId
    );
    onValue(subscriptionRef, (snapshot) => {
      const data = snapshot.val();
      
      console.log(data);
      setSubscription(data);
    });
  }
  
  useEffect(() => {
    if(user){
      listenToSubscriptionData();
    }
    
    // eslint-disable-next-line
  }, [user]);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const handleUser = async (rawUser, credentials) => {
    // console.log("handle user");
    // console.log("handle user");
    if (rawUser) {
      const user = rawUser;

      const docRef = doc(db, "users", user.uid);

      const data = {
        uid: user.uid,
        displayName: user.displayName,
        ...user["providerData"][0],
        ...credentials,
        timeSlots: ["0900-1200", "1201-1800", "1801-2359"],
      };

      if (data["providerId"] === "google.com") {
        data["oauthAccessToken"] = null;
        data["oauthTokenSecret"] = null;
        data["oauthExpireIn"] = null;
        data["oauthIdToken"] = null;
      }

      if (Object.keys(credentials).length === 0) {
        setLoading(false);
        let userObj = await getDoc(docRef);
        let userRtdb = await get(child(ref(rtdb), `users/${user.uid}`));
        let userSub = await get(child(ref(rtdb), `subscription/${user.uid}`));
        //console.log(userRtdb.val());
        setUser({
          ...userObj.data(),
          ...userRtdb.val(),
        });
        setSubscription({
          ...userSub.val(),
        })
        // console.log();
      } else {
        await setDoc(docRef, data);
        await set(ref(rtdb, `users/${user.uid}`), data);
        await set(ref(rtdb, `subscription/${user.uid}`), {
          uid: user.uid,
          credits: 0,
          createdAt: Date.now(),
          status: "trial"
        })
        setLoading(false);
        setUser(data);
        setSubscription({
          uid: user.uid,
          credits: 0,
          createdAt: Date.now(),
          status: "trial"
        });
        console.log(data);
      }

      return data;
    } else {
      setLoading(false);
      setUser(false);
      return false;
    }
  };

  const signinWithTwitter = () => {
    console.log("Signin with twitter");
    const twitterProvider = new TwitterAuthProvider();
    setLoading(true);
    return signInWithPopup(auth, twitterProvider).then((response) =>
      handleUser(response.user, response["_tokenResponse"])
    );
  };

  const signinWithGoogle = () => {
    console.log("Signin with google");
    const googleProvider = new GoogleAuthProvider();
    setLoading(true);
    return signInWithPopup(auth, googleProvider).then((response) =>
      handleUser(response.user, response["_tokenResponse"])
    );
  };

  const signInWithEmailandPass = (email, password) => {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        handleUser(response.user, response["_tokenResponse"]);
        dispatch(
          showNotification({ message: `Successfully Login`, status: 1 })
        );
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          return createUserWithEmailAndPassword(auth, email, password)
            .then((response) => {
              handleUser(response.user, response["_tokenResponse"]);
              dispatch(
                showNotification({
                  message: `Successfully create account`,
                  status: 1,
                })
              );
            })
            .catch((error) => {
              console.error("Error creating user:", error);
              dispatch(
                showNotification({
                  message: `Error creating user: ${error.message}`,
                  status: 0,
                })
              );
            });
        } else if (error.code === "auth/wrong-password") {
          console.log("wrong-password error:", error);
          dispatch(
            showNotification({
              message: `Wrong password. Please try again.`,
              status: 0,
            })
          );
        } else if (error.code === "auth/invalid-email") {
          console.log("invalid-email error:", error);
          dispatch(
            showNotification({
              message: `Invalid Email. Please try again.`,
              status: 0,
            })
          );
        } else if (error.code === "auth/missing-password") {
          console.log("missing-password error:", error);
          dispatch(
            showNotification({
              message: `Please enter the password.`,
              status: 0,
            })
          );
        } else {
          console.error("Error signing in:", error);
          dispatch(
            showNotification({
              message: `Error signing in: ${error.message}`,
              status: 0,
            })
          );
        }
      });
  };

  // const signInWithEmailandPass = (email, password) => {
  //   setLoading(true);
  //   return signInWithEmailAndPassword(auth, email, password)
  //     .then((response) => handleUser(response.user, response["_tokenResponse"]))
  //     .catch((error) => {
  //       if (error.code === "auth/user-not-found") {
  //         return createUserWithEmailAndPassword(auth, email, password)
  //           .then((response) => {
  //             handleUser(response.user, response["_tokenResponse"]);
  //           })
  //           .catch((error) => {
  //             if(error.code === 'auth/auth/wrong-password'){
  //               console.error("Invalid password:", error);
  //             }
              
  //           });
  //       } else {
  //         console.error("Error signing in:", error);
  //       }
  //     });
  // };

  const signinWithAnnon = () => {
    // const twitterProvider = new TwitterAuthProvider();
    setLoading(true);
    return signInAnonymously(auth).then((response) =>
      handleUser(response.user, response["_tokenResponse"])
    );
  };

  const signout = () => {
    return signOut(auth).then(() => handleUser(false, {}));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      handleUser(user, {});
    });

    return () => unsubscribe();
  }, []);

  return {
    user,
    subscription,
    loading,
    functions,
    db,
    rtdb,
    storage,
    signinWithTwitter,
    signinWithGoogle,
    signinWithAnnon,
    signout,
    signInWithEmailandPass,
  };
}

const checkAuth = () => {
  /*  Getting token value stored in localstorage, if token is not present we will open login page 
      for all internal dashboard routes  */
  const TOKEN = localStorage.getItem("token");
  const PUBLIC_ROUTES = [
    "login",
    "forgot-password",
    "register",
    "documentation",
  ];

  const isPublicPage = PUBLIC_ROUTES.some((r) =>
    window.location.href.includes(r)
  );

  if (!TOKEN && !isPublicPage) {
    window.location.href = "/login";
    return;
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

    axios.interceptors.request.use(
      function (config) {
        // UPDATE: Add this code to show global loading indicator
        document.body.classList.add("loading-indicator");
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // UPDATE: Add this code to hide global loading indicator
        document.body.classList.remove("loading-indicator");
        return response;
      },
      function (error) {
        document.body.classList.remove("loading-indicator");
        return Promise.reject(error);
      }
    );
    return TOKEN;
  }
};

export default checkAuth;
